/**
 * Use Labels as Placeholders
 *
 * This script uses the defined form's labels as placeholders
 * and hides the labels, but retains them for screen readers.
 *
 * @author Jonathan Brockett
 * @requires jQuery.js
 * @see {@link https://jquery.com/}
 */

$(document).ready(function() {

  // Vars
  var formID = '#contactform';


  // Convert Labels into Placeholders
  $(formID + ' :input').each(function(index, elem) {
    var eId = $(elem).attr('id');
    var label = null;
    if (eId && (label = $(elem).parents('form').find('label[for=' + eId + ']')).length == 1) {
      $(elem).attr('placeholder', $(label).html());
      $(label).addClass('sr-only');
    }
  });
});
