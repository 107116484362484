/**
 * 
 * make the custom featured news slide left to right.
 * 
 * Notice we set items:1 for all device sizes.
 * 
 * @author Michael Born
 * @date 10/26/2017
 * @requires https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.2.1/owl.carousel.min.js
 * @requires https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.2.1/assets/owl.carousel.min.css
 * @requires https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.2.1/assets/owl.theme.default.min.css
 * @see {@link http://owlcarousel2.github.io/OwlCarousel2/}
 * @see inc/zone-custom-featured-news.cfm
*/
$(document).ready(function() {
	$("#featuredNews")
		.addClass("owl-carousel owl-theme")
		.owlCarousel({
			loop:true,
			nav:false,
			dots:true,
			items:1
		});
});