/**
 * Set Columns to Equal Height
 *
 * This script sets the defined element to have equal column
 * heights and recalculates on window resize.
 *
 * @author Jonathan Brockett
 * @requires jQuery.js
 * @see {@link https://jquery.com/}
 * @requires jquery.matchHeight.js
 * @see {@link https://github.com/liabru/jquery-match-height}
 */

//Vars
var equalColumn = '.location-box';

$(function() {
  $(equalColumn).matchHeight();
});
