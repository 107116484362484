$(window).load(function() {
  //For single-page websites, have a smooth scroll to the appropriate section. Include an offset for the header.
  anchorScroll({
    theAnchorLinks: $("#container").find("a"), //this is a selector for all anchor links.
    //so the header doesn't show over the top of the section we're scrolling to.
    //make sure to use a negative number if you want the scroll amount to be LESS.
    offset: -129,
    //how long to transition between sections, in milliseconds. (1000=1s)
    transitionTime: 1000
  });
});
var anchorScroll = function(opts) {
  /**
   *
   * @url https://gist.github.com/mborn319/212e6451846b1369b4e4
   * @author Michael Born
   * @date 2016?
   */
  var page = {
    options: typeof opts !== "undefined" ? opts : {}
  };


  page.init = function() {
    var self = this;
    self.options.theAnchorLinks.on("click", function(e) {
      self.whenClicked(e, this, self);
    });
    if (location.hash !== "") { //if page has #link in url.
      self.scrollToDiv(location.hash);
    }
    //console.log(this);
    return this;
  };
  page.whenClicked = function(ev, link, obj) {
    var self = obj,
      strHash = link.hash,
      linkHref = link.href.split('#')[0]; //get first part of URL

    //if on same page, use scrolling
    if (strHash && self.isCurrentURL(linkHref)) {
      ev.preventDefault();

      self.scrollToDiv(strHash);
      self.changeURL($(link).html(), strHash);
    }
  }; //whenClicked() func
  page.isCurrentURL = function(url1) {
    return url1 === window.location.protocol + "//" + window.location.host + window.location.pathname;
  }; //isCurrentURL() func
  page.changeURL = function(pageTitle, hash) {
    //html5 add state to history api
    window.history.pushState(null, pageTitle, hash);
  }; //changeURL() func
  page.scrollToDiv = function(divID) {
    var $div = $(divID),
      scrollTo;
    if (!$div.length) {
      //attempt to find the scroll amount based on an a[name="contact"], etc.
      $div = $('a[name="' + divID.replace('#', '') + '"]');
    }
    //console.log("attempting to scroll to:",$div);
    if ($div.length) {
      scrollTo = $div.offset().top;
      return this.scrollToPX(scrollTo);
    }
    return false;
  }; //scrollToDiv() func
  page.scrollToPX = function(pxAmount) {
    var self = this,
      amount = pxAmount + self.options.offset,
      $root = $("html, body");
    //console.info("scrolling page by a certain pixel amount:",amount);
    $root.stop().animate({
      scrollTop: amount
    }, self.options.transitionTime, "swing");
    return true;
  }; //scrollToPX() func
  return page.init();
};
