/**
 * Convert Menu to Bootstrap Markup
 *
 * This script alters the default tSpark CMS 4 slidedown menu
 * to use Bootstrap 3 markup.
 *
 * @author Jonathan Brockett
 * @requires jQuery.js
 * @see {@link https://jquery.com/}
 * @requires cmsframework4
 */

$(document).ready(function() {

  //Vars
  var mainMenu = $('#main-menu');


  // Bootstrap slide dropdown menu
  $('.dropdown').on('show.bs.dropdown', function(e){
    $(this).find('.dropdown-menu').first().stop(true, true).slideDown(300);
  });

  $('.dropdown').on('hide.bs.dropdown', function(e){
    $(this).find('.dropdown-menu').first().stop(true, true).slideUp(200);
  });

  // Adds Bootstrap classes to the menu and removes CMS classes
  mainMenu.find('.menu-pulldown').removeClass('menu-pulldown');
  mainMenu.find('ul.level1').addClass('nav navbar-nav');
  mainMenu.find('ul li.hasSubmenu').addClass('dropdown');
  mainMenu.find('ul li.hasSubmenu > a').addClass('dropdown-toggle');
  mainMenu.find('ul li.level2 a').removeClass('dropdown-toggle');
  mainMenu.find('ul.level2').addClass('dropdown-menu');
  mainMenu.find('ul.level2').css('display', '');
  mainMenu.find('li.dropdown a.dropdown-toggle').append('<span class="caret"></span>');
  mainMenu.find('.dropdown-toggle').attr('data-toggle', 'dropdown').attr('role', 'button').attr('aria-haspopup', 'true').attr('aria-expanded', 'false');
  mainMenu.find('li').unbind("mouseenter mouseleave");
});
