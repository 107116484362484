/**
 * Back to Top Button
 *
 * This script animates the back to top button link
 * to smoothly scroll to the top of the page
 *
 * @author Jonathan Brockett
 * @requires jQuery.js
 * @see {@link https://jquery.com/}
 */

$(document).ready(function() {

  /**
   * Vars
   */
  var backToTopLink = '#back-to-top a';


  // Animation for 'Back to Top' button
  $(backToTopLink).click(function() {
    $('html, body').animate({
      scrollTop: 0
    }, 1200);
    return false;
  });
});
