/**
 * Affixes the Header on Scroll
 *
 * This script uses Bootstrap 3 affix to set position fixed
 * after scrolling past the header.
 *
 * @author Jonathan Brockett
 * @requires jQuery.js
 * @see {@link https://jquery.com/}
 * @requires bootstrap.js
 * @see {@link https://getbootstrap.com/docs/3.3/javascript/#affix}
 */

$(document).ready(function() {

  /**
   * Vars
   */
  var header = '#header';
  var xsMedia = '480'; // Bootstrap Default in px
  var smMedia = '768'; // Bootstrap Default in px
  var mdMedia = '992'; // Bootstrap Default in px
  var lgMedia = '1200'; // Bootstrap Default in px

  function headerAffix() {
    $(header).affix({
      offset: {
        /*top: function() {
          return (this.top = $(header).outerHeight(true));
        }*/
        top: 0
      }
    });
  }

  headerAffix(); // Initial

  window.addEventListener("resize", function() {
    if ($(window).width() < xsMedia) {
      headerAffix();
    } else if (window.matchMedia('(min-width: ' + xsMedia + 'px)').matches) {
      headerAffix();
    } else if (window.matchMedia('(min-width: ' + smMedia + 'px)').matches) {
      headerAffix();
    } else if (window.matchMedia('(min-width: ' + mdMedia + 'px)').matches) {
      headerAffix();
    } else if (window.matchMedia('(min-width: ' + lgMedia + 'px)').matches) {
      headerAffix();
    }
  }, false);

});
